import { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Form,
  FormContainer,
  FormWrapper,
  Input,
  Option,
  PageName,
  Select
} from './BookmarkForm.elements'
import { Button, ExitButton } from '../../globalStyles'
import { VscClose } from 'react-icons/vsc'
import { IconContext } from 'react-icons/lib'


const BookmarkForm = ({ addNewBookmark, setShowForm }) => {
  const [inputValues, setInputValues] = useState({ name: '', folder: 'All Bookmarks', url: 'https://' })
  // const [newFolder, setNewFolder] = useState(false)
  const folders = useSelector((state) => state.folders)

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    setInputValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      }
    })
  }

  const handleAddBookmark = (event) => {
    event.preventDefault()
    const name = inputValues?.name
    const folder = inputValues?.folder
    const url = inputValues?.url
    const date = Date()
    const favorite = false

    const bookmark = {
      name,
      folder,
      url,
      favorite,
      date
    }

    addNewBookmark(folder, bookmark)
    setInputValues({ folder: '', name: '', url: 'https://' })
    setShowForm(false)
  }

  const handleKey = (e) => {
    if (e.which === 13) {
      e.preventDefault()
    }
  }

  // const forNewFolder = () => {
  //   setNewFolder(!newFolder)
  //   setInputValues((prevValues) => {
  //     return {
  //       ...prevValues,
  //       folder: '',
  //     }
  //   })
  // }

  return (

    <IconContext.Provider value={{ size: 26, color: '#65656d' }}>
      <FormContainer>
        <FormWrapper>
          <PageName>
            Add a new bookmark
            <ExitButton right onClick={() => setShowForm(false)}>
              <VscClose />
            </ExitButton>
          </PageName>
          <Form onSubmit={handleAddBookmark}>
            <div>
              Name
              <br />
              <Input
                required
                name="name"
                onKeyDown={handleKey}
                value={inputValues?.name || ''}
                onChange={handleInputChange}
              />
            </div>
            <div>
              Folder
              <br />
              <Select disabled={folders.length === 0} name="folder" value={inputValues?.folder || ''}
                onChange={handleInputChange}>
                {/* <option
                    value="All Bookmarks"
                    key="All Bookmarks">All Bookmarks</option> */}
                {folders.map((folder) => (
                  <Option
                    value={folder.id}
                    key={folder.id}>{folder.name}</Option>
                ))}
              </Select>
            </div>
            <div style={{ marginBottom: '20px' }}>
              Url
              <br />
              <Input
                required
                name="url"
                onKeyDown={handleKey}
                value={inputValues?.url || ''}
                onChange={handleInputChange}
              />
            </div>
            {/* <Button variant={'#252525'} onClick={() => forNewFolder()} type="button">{newFolder ? 'Existing Folder' : 'New Folder'}</Button> */}
            {folders.length === 0 ? <p style={{ color: '#dd5555' }}>Must create a folder first</p> :
              <Button variant={'#15ac61'} style={{ float: 'right' }} type="submit">Save</Button>
            }
          </Form>
        </FormWrapper>
      </FormContainer>
    </IconContext.Provider>
  )
}

export default BookmarkForm

BookmarkForm.propTypes = {
  addNewBookmark: PropTypes.func.isRequired,
}

