import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../globalStyles'
import { Form, FormContainer, FormWrapper, Input, PageName } from './SignupForm.elements'

const LoginForm = ({ signup }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState('000')

  const handleSignUp = (event) => {
    event.preventDefault()
    if (!token) {
      console.log('You must verify captcha')
      return
    }

    try {
      signup(username, password, token)
      setUsername('')
      setPassword('')
      // captcha.current.resetCaptcha()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <FormContainer>
      <FormWrapper>
        <PageName>Sign up</PageName>
        <Form onSubmit={handleSignUp}>
          <div className="inputs">
            Username:
            <br />
            <Input
              id="username"
              type="text"
              value={username}
              title="Username"
              onChange={({ target }) => setUsername(target.value)}
            />
          </div>
          <div className="inputs">
            Password:
            <br />
            <Input
              id="password"
              type="password"
              value={password}
              title="Password"
              onChange={({ target }) => setPassword(target.value)}
            />
          </div>
          {/* <div>
            <HCaptcha
              ref={captcha}
              sitekey={config.hCaptchaSiteToken}
              onVerify={token => setToken(token)}
              onExpire={() => setToken('')}
            />
          </div> */}
          <p style={{ color: '#dd5555' }}>⚠️ Disclaimer: This is a test app and, by no means, is production ready. It is only meant to showcase functionality. There currently is no password recovery!</p>
          <Link to='/login' style={{ color: '#9999dd' }}>Already a user? Log in</Link>
          <Button right variant={'#15ac61'} id="login-button" type="submit">
            Sign Up
          </Button>
        </Form>
      </FormWrapper>
    </FormContainer>
  )
}

export default LoginForm
