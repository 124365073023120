import LoginForm from '../components/LoginForm/LoginForm'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../reducers/loginReducer'
import { Link, useNavigate } from 'react-router-dom'

const Login = () => {
  const user = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogin = async (username, password) => {
    try {
      await dispatch(login(username, password))
      navigate('/')
      window.location.reload()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      {user === null || user === undefined ? (
        <LoginForm login={handleLogin} />
      ) : (
        <div style={{ margin: '10vh' }}>
          <p>
            Currently logged in as <strong>{user.name} </strong>
          </p>
          <Link to='/'>Go back home</Link>
        </div>
      )}
    </>
  )
}

export default Login
