import styled from 'styled-components'

export const SearchContainer = styled.div`
    height: 50px;
    width: 100%;
    transition: 0.2s;
    position: absolute;
    text-align: center;
    z-index: -1;
    top: 100px;
`

export const TextContainer = styled.div`
    width: 88px;
    height: 42px;
    display: inline-block;
    text-align: center;
`

export const IconWrap = styled.button`
    width: 30px;
    height: 30px;
    right: 0;
    line-height: 1px;
    color: #999;
    background-color: #55555500;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    position: absolute;
    margin: 8px;
`

export const SearchBar = styled.form`
    top: 20px;
    position: absolute;
    z-index: 3;
    transition: 0.2s ease-in-out;
    animation-delay: 0.1s;
    top: 20px;
    margin-left: -10.5vw;
    width: 25%;

    @media screen and (max-width: 991px) {
        opacity: 0;
    }
`

export const SearchInput = styled.input`
    padding: 11px 0px 11px 16px;
    font-size: 17px;
    outline: none;
    border: none;
    border-radius: 1.2rem;
    float: left;
    background: rgba(105,105,105,0.2);
    color: #909090;
    width: 100%;

    &:focus {
        background: rgba(195,195,195,1);
        color: #757575;
        outline: rgba(255,255,255,0.5) 2px solid;
    }

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
        -webkit-appearance: none;
        appearance: none;
    }

    &::placeholder {
        color: #8a8a8a;
    }
`