import axios from 'axios'
const baseUrl = 'https://anaquel-backend.vercel.app'

const login = async (credentials) => {
  const response = await axios.post(`${baseUrl}/api/login`, credentials)
  return response.data
}

const signup = async (credentials) => {
  const response = await axios.post(`${baseUrl}/api/users`, credentials)
  return response.data
}

export default { login, signup }