import React, { useState } from 'react'
import {
  ButtonContainer,
  ButtonWrapper,
  Option,
  Sidedrop,
  SidedropWrapper,
  UIContainer
} from './SideButtons.elements'
import { IconContext } from 'react-icons/lib'
import { FiPlus, FiSettings } from 'react-icons/fi'
import { TbSortDescending, TbEdit } from 'react-icons/tb'

const SideButtons = ({ expand, showForm, setShowForm, editModeF, setEditModeF, editModeB, setEditModeB, setSettingsMode, folderForm, setFolderForm, setSort, handleSort, sort }) => {
  // folders
  // const [folderSort, setFolderSort] = useState(false)

  // const settingSortFolders = (sel) => {
  //   setSortFolders(sel)
  //   return sel
  // }

  // const handleSortSelectionFolders = async (selection) => {
  //   const response = await settingSortFolders(selection)
  //   return handleSortFolders(response)
  // }

  // bookmarks
  const [bookmarkSort, setBookmarkSort] = useState(false)

  const settingSortBookmarks = (sel) => {
    setSort(sel)
    return sel
  }

  const handleSortSelectionBookmarks = async (selection) => {
    setBookmarkSort(false)
    const response = await settingSortBookmarks(selection)
    return handleSort(response)
  }

  return (
    <>
      <IconContext.Provider value={{ size: 16, color: '#888' }}>
        <UIContainer isRight={false} expand={expand}>
          <ButtonContainer>
            <ButtonWrapper
              title="Add Folder"
              onClick={() => setFolderForm(!folderForm)}
            >
              <FiPlus />
            </ButtonWrapper>
          </ButtonContainer>

          {/* <ButtonContainer>
            <ButtonWrapper
              title="Sort Folders"
              onClick={() => setFolderSort(!folderSort)}
            >
              <TbSortDescending />
            </ButtonWrapper>
          </ButtonContainer>

          <SidedropWrapper right showSort={folderSort}>
            <Sidedrop>
              <Option onClick={() => handleSortSelectionFolders('NEW')}>Newer</Option>
              <Option onClick={() => handleSortSelectionFolders('OLD')}>Older</Option>
              <Option onClick={() => handleSortSelectionFolders('AZ')}>A - Z</Option>
              <Option onClick={() => handleSortSelectionFolders('ZA')}>Z - A</Option>
            </Sidedrop>
          </SidedropWrapper> */}

          <ButtonContainer
            editMode={editModeF}
          >
            <ButtonWrapper
              title="Edit Folders"
              editMode={editModeF}
              onClick={() => setEditModeF(!editModeF)}
            >
              <TbEdit />
            </ButtonWrapper>
          </ButtonContainer>

          <ButtonContainer>
            <ButtonWrapper
              title="Settings"
              onClick={() => setSettingsMode(true)}
            >
              <FiSettings />
            </ButtonWrapper>
          </ButtonContainer>
        </UIContainer>

        <UIContainer isRight={true} expand={expand}>
          <ButtonContainer>
            <ButtonWrapper
              title="Add Bookmark"
              onClick={() => setShowForm(!showForm)}
            >
              <FiPlus />
            </ButtonWrapper>
          </ButtonContainer>

          <ButtonContainer
            editMode={editModeB}
          >
            <ButtonWrapper
              title="Edit Bookmarks"
              editMode={editModeB}
              onClick={() => setEditModeB(!editModeB)}
            >
              <TbEdit />
            </ButtonWrapper>
          </ButtonContainer>

          <ButtonContainer>
            <ButtonWrapper
              title="Sort Bookmarks"
              onClick={() => setBookmarkSort(!bookmarkSort)}
            >
              <TbSortDescending />
            </ButtonWrapper>
          </ButtonContainer>

          <SidedropWrapper showSort={bookmarkSort}>
            <Sidedrop>
              <Option isSelected={sort === 'NEW'} onClick={() => handleSortSelectionBookmarks('NEW')}>Newer</Option>
              <Option isSelected={sort === 'OLD'} onClick={() => handleSortSelectionBookmarks('OLD')}>Older</Option>
              <Option isSelected={sort === 'AZ'} onClick={() => handleSortSelectionBookmarks('AZ')}>A - Z</Option>
              <Option isSelected={sort === 'ZA'} onClick={() => handleSortSelectionBookmarks('ZA')}>Z - A</Option>
            </Sidedrop>
          </SidedropWrapper>
        </UIContainer>
      </IconContext.Provider>
    </>
  )
}

export default SideButtons

