import styled from 'styled-components'

export const BookmarksContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    user-select: none;   
    overflow-y: ${({ expand }) => (expand ? 'overlay' : 'hidden')};  
    padding: 1.5vw 1.3vw 0 1.3vw;

    @media screen and (max-width: 991px) {
        padding: 9.75vw 1.3vw 0 1.3vw;
    }
`

export const WebsiteCard = styled.div`
    height: 9rem;
    width: 6.5rem;
    margin: 1vw;
    display: inline-block;
    vertical-align: top;
    
    @media screen and (max-width: 1500px) { 
        height: 6.5rem;
        width: 4.8rem;
    }

    @media screen and (max-width: 991px) {
        height: 8.5rem;
        width: 6.1rem;
    }
`

export const WebsiteApp = styled.div`
    height: 72.5%;
    padding-left: 0.9rem;
    border-radius: 20px;
    background-color: #222;
`


export const WebsiteContainer = styled.div`
    width: 100%;
    height: 6.5rem;
    display: inline-block;
    cursor: pointer;
    position: relative;
    outline: 4px solid #111;
    border-radius: 20px;

    @media screen and (max-width: 1500px) { 
        height: 6.1rem;
    }
`

export const WebsiteWrapper = styled.a`
    width: 100%;
    height: 100%;
    display: inline-block;
    background-color: #333;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    border: 2px solid #8880;

    &:hover {
        background-color: #222;
        border: 2px solid #2c2c2c;
    }

    &:active {
        border: 2px solid #888;
    }
`

export const DeleteButton = styled.span`
    font-size: 10px;
    line-height: 12px;
    height: 19px;
    width: 19px;
    padding: 3px 5px;
    border-radius: 12px;
    position: absolute;
    top: 0;
    display: inline-block;
    vertical-align: top;
    background-color: #656565;    
    outline: 0px solid #656565;
    color: #f0f0f0;
    z-index: 999999999;
    margin: -3px;
    transition: 0.1s;
    scale: 0;
    scale: ${({ editMode }) => (editMode ? '1' : '0')};
    z-index: 10;

    &:hover {
        background-color: #a40004;
        outline: 2px solid #a40004;
        outline-offset: -1px;
    }
`

export const WebsiteImage = styled.img`
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: center; 
    opacity: 0.9;
    padding: 4px;
    border-radius: 1.15em;
`

/////

export const NameContainer = styled.div`
    height: 30px;
    width: 100%;
    overflow: hidden;
`

export const NameWrapper = styled.div`
    height: 30px;
    width: 100vw;
`

export const Name = styled.a`
    text-decoration: none;
    color: #888;
    font-size: 14px;
    line-height: 26px;
    margin-top: 0px;
    cursor: pointer;
    font-weight: 300;
    display: inline-block;
    vertical-align: top;
    user-select: text;

    &:hover {
        color: #888;
    }
`

/////

export const IPFS = styled.div`
    width: 4px;
    height: 4px;
    padding: 3px;
    margin-right: 4px;
    margin-left: 1px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 10px;
    z-index: 2;
    display: ${({ isIPFS }) => (isIPFS ? '' : 'none')};
    outline: ${({ isPinned }) => (isPinned ? '4px solid #69c4cd' : '1px solid #69c4cd')};
    outline-offset: ${({ isPinned }) => (isPinned ? '-4px' : '-1px solid #69c4cd')};
`

export const Pinned = styled.span`
    font-size: 8px;
    height: 18px;
    padding: 2px 4px;
    margin-right: 2px;
    background-color: #242f40;
    border-radius: 12px;
    z-index: 2;
    display: ${({ isIPFS }) => (isIPFS ? '' : 'none')};
`

export const SpacerXSmall = styled.div`
    display: block;
    bottom: 0;
    height: 3.8em;
    width: 100%;
    transition: 0.2s;
    height: ${({ expand }) => (expand ? '3.8em' : '0')};
`