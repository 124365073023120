import SignupForm from '../components/SignupForm/SignupForm'
import { useDispatch, useSelector } from 'react-redux'
import { signup } from '../reducers/loginReducer'
import { Link, useNavigate } from 'react-router-dom'

const Login = () => {
  const user = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSignup = async (username, password, token) => {
    try {
      await dispatch(signup(username, password, token))
      alert('Your account was succesfully created! Please log in with your credentials')
      navigate('/login')
      window.location.reload()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      {user === null || user === undefined ? (
        <SignupForm signup={handleSignup} />
      ) : (
        <div style={{ margin: '10vh' }}>
          <p>
            Currently logged in as <strong>{user.name} </strong>
          </p>
          <Link to='/'>Go back home</Link>
        </div>
      )}
    </>
  )
}

export default Login
