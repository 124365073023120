import styled from 'styled-components'

export const UIContainer = styled.div`
    width: 16%;
    height: 100%;
    position: absolute;
    text-align: center;
    margin-top: 44vh;
    transition: 0.2s;
    opacity: ${({ expand }) => (expand ? 1 : 0)};
    margin-left: ${({ expand }) => (expand ? '0' : '2em')};
    margin-right: ${({ expand }) => (expand ? '0' : '2em')};

    ${({ isRight }) => (isRight ? 'right:0;' : '')};
`

export const ButtonContainer = styled.div`
    width: 100%;
    height: 33px;
    text-decoration: none;
    display: block;
    text-align: center;
    margin-bottom: 1em;
    z-index: 999;
`

export const ButtonWrapper = styled.div`
    width: 34px;
    height: 34px;
    margin: auto;
    line-height: 1.9em;
    color: #787878;
    cursor: pointer;
    background-color: #333;
    background-color: ${({ custom }) => (custom)};
    border-radius: 50px;
    transition: 0.1s;
    outline: 0px solid #0482ff;
    outline: ${({ editMode }) => (editMode ? '3px solid #0482ff' : '')};
    z-index: 99;

    &:hover {
        background-color: #2a2a2a;
    }
`


export const SidedropWrapper = styled.div` 
    position: absolute;
    pointer-events: none;
    display: ${({ showSort }) => (showSort ? 'block' : 'none')};
    transform: translate(${({ right }) => (right ? '2vw' : '-2vw')}, -100px);
    right: ${({ right }) => (right ? '0' : '')};
`

export const Sidedrop = styled.div` 
    width: 150px;
    border-radius: 10px;
    background-color: #222;
    outline: 1px solid #434343;
    overflow: hidden;
    pointer-events: all;
`


export const Option = styled.p` 
    color: #787878;
    font-size: 13px;
    line-height: 29px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 0;
    pointer-events: all;
    background-color: ${({ isSelected }) => (isSelected ? '#454545' : '#0482ff00')};
    color: ${({ isSelected }) => (isSelected ? '#f0f0f0' : '#787878')};

    &:hover {
        background-color: #0482ff;
        color: #f0f0f0;
    }

    &:active {
        background-color: #409fff;
    }
`