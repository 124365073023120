import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import bookmarkReducer from './reducers/bookmarkReducer'
import folderReducer from './reducers/folderReducer'
import loginReducer from './reducers/loginReducer'

const reducer = combineReducers({
  bookmarks: bookmarkReducer,
  folders: folderReducer,
  login: loginReducer,
})

const store = createStore(reducer, applyMiddleware(thunk))

export default store