import axios from 'axios'
const baseUrl = 'https://anaquel-backend.vercel.app/api/folders'

let token = null

const setToken = (newToken) => {
  token = `bearer ${newToken}`
}

const clearToken = () => {
  token = null
}

//FOLDERS
const getAllFolders = (user) => {
  const request = axios.get(`${baseUrl}?&user=${user}`)
  return request.then((response) => response.data)
}

const createFolder = async (newObject) => {
  const config = {
    headers: { Authorization: token },
  }

  const response = await axios.post(baseUrl, newObject, config)
  return response.data
}

const updateFolder = (id, newObject) => {
  const request = axios.put(`${baseUrl}/${id}`, newObject)
  return request.then((response) => response.data)
}

const removeFolder = (id) => {
  axios.delete(`${baseUrl}/${id}`)
}


// BOOKMARKS
const getAll = (user) => {
  const request = axios.get(`${baseUrl}?&user=${user}`)
  return request.then((response) => response.data)
}

const create = async (id, newObject) => {
  const config = {
    headers: { Authorization: token },
  }

  const response = await axios.post(`${baseUrl}/${id}/bookmarks`, newObject, config)
  return response.data
}

const update = (id, newObject) => {
  const request = axios.put(`${baseUrl}/${id}`, newObject)
  return request.then((response) => response.data)
}

const remove = (id) => {
  axios.delete(`${baseUrl}/bookmarks/${id}`)
}

const exportedObject = {
  token,
  getAll,
  create,
  update,
  remove,
  getAllFolders,
  createFolder,
  updateFolder,
  removeFolder,
  setToken,
  clearToken
}

export default exportedObject
