import { createGlobalStyle } from 'styled-components'
import styled from 'styled-components/macro'

const GlobalStyle = createGlobalStyle`

    *{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }    

    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #141415;
        color: #9a9a9a;
        font-family: Shippori Antique, Arial, Helvetica, sans-serif;
        letter-spacing: -0.025em;
        overflow: hidden;
    }

    
    h1 {
        color: #000000;
        margin-block-start: 0em;
        margin-block-end: 0.5em;
    }

    h2 {
        color: #000000;
        margin-block-start: 0em;
        margin-block-end: 0.5em;
    }

    input {
        border: none;
        padding: 10px 12px;
        border-radius: 5px;
        font-size: 1em;
        border: 1px solid #c4c4c4;
    }

    /* width */
    &::-webkit-scrollbar {
        width: 6px;    
        cursor: pointer;
    }

    /* width */
    &::-webkit-scrollbar:hover {
        width: 16px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        margin: 20px;
        margin-top: 4.25em;
        border-radius: 20px;
        cursor: pointer;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #555; 
        border-radius: 20px;
        cursor: pointer;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #333;   
        cursor: pointer;
        width: 10px;
    }
`

export const Button = styled.button`
    font-size: 18px;
    color: #f0f0f0;
    float: ${({ right }) => (right && 'right')};
    margin-bottom: ${({ right }) => (right && '26px')};
    background-color: ${({ variant }) => (variant)};
    padding: 10px 14px;
    border-radius: 8px;
    border: none;
    transition: 0.2s;

    &:hover {
        background-color: ${({ variant }) => (variant)}dd;
    }
`

export const ExitButton = styled.button`
    float: ${({ right }) => (right && 'right')};
    background-color: #111111;
    outline: 1px solid #2d2d31;
    line-height: 22px;
    padding: 5px 7px 6px 7px;
    margin-top: -7px;
    border-radius: 80px;
    vertical-align: top;
    border: none;
    transition: 0.2s;
    display: inline;
    z-index: 9999999999;
    pointer-events: all;
    cursor: pointer;

    &:hover {
        background-color: ${({ variant }) => (variant)}dd;
        outline: 1px solid #65656d;
    }
`

export const TempImage = styled.img`
    height: 100%;
    width: 100%;
    overflow: hidden;
    object-fit: contain;
    object-position: center; 
    opacity: 0.3;
    padding: 20px;
`

export const TempIcon = styled.img`
    width: 100%;
    height: 100%;
    vertical-align: top;
    object-fit: contain;
    object-position: left;
    border-radius: 12px;
    padding: 10px 14px;
    opacity: 0.25;
`

export default GlobalStyle