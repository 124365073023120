/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import {
  Container,
  Wrapper,
  SearchInput,
  SearchBar,
  FolderTrayContainer,
  FolderTrayWrapper,
  MarksTrayContainer,
  MarksTrayWrapper,
  ExitSearchButton,
  SearchBack,
  Notice
} from './Tray.elements'
import Search from '../Search/Search'
import Folders from '../Folders/Folders'
import Bookmarks from '../Bookmarks/Bookmarks'
import SideButtons from '../SideButtons/SideButtons'
import { useSelector } from 'react-redux'

const Tray = ({ expand, showForm, setShowForm, setSettingsMode, folderForm, setFolderForm, setEditModeB, editModeB, setEditModeF, editModeF }) => {
  /// BOOKMARKS
  const bookmarks = useSelector((state) => state.bookmarks)
  const bookmarksByNew = [...bookmarks].reverse()

  const [sort, setSort] = useState('NEW')
  const [currBookmarks, setCurrBookmarks] = useState({ sorted: bookmarksByNew, unsorted: bookmarks })

  const handleSort = (selection) => {
    const sortAz = currBookmarks.unsorted?.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })

    if (selection === 'AZ') {
      setCurrBookmarks({ ...currBookmarks, sorted: sortAz })
    } else if (selection === 'ZA') {
      setCurrBookmarks({ ...currBookmarks, sorted: sortAz.reverse() })
    } else if (selection === 'OLD') {
      setCurrBookmarks({ ...currBookmarks, sorted: currBookmarks.unsorted })
    } else {
      const unsortedArr = currBookmarks.unsorted
      const sortedByNew = unsortedArr?.reverse()
      setCurrBookmarks({ ...currBookmarks, sorted: sortedByNew })
    }
  }

  /// FOLDERS
  const folders = useSelector((state) => state.folders)
  const foldersByNew = [...folders].reverse()

  const [sortFolders, setSortFolders] = useState('NEW')
  const [currFolders, setCurrFolders] = useState({ sorted: foldersByNew, unsorted: folders })

  const handleSortFolders = (selection) => {
    const sortAz = currBookmarks.unsorted?.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })

    if (selection === 'AZ') {
      setCurrFolders({ ...currFolders, sorted: sortAz })
    } else if (selection === 'ZA') {
      setCurrFolders({ ...currFolders, sorted: sortAz.reverse() })
    } else if (selection === 'OLD') {
      setCurrFolders({ ...currFolders, sorted: currFolders?.unsorted })
    } else {
      const unsortedArr = currFolders?.unsorted
      const sortedByNew = unsortedArr?.reverse()
      setCurrFolders({ ...currFolders, sorted: sortedByNew })
    }
  }

  // SEARCH - SIMULTANEOUS on folders and bookmarks
  const [title, setTitle] = useState('')
  const [foundBookmarks, setFoundBookmarks] = useState(null)
  const [foundFolders, setFoundFolders] = useState(null)

  const filter = (e) => {
    setFoundBookmarks(currBookmarks.sorted)
    setFoundFolders(currFolders.sorted)
    const keyword = e.target.value

    if (keyword !== '') {
      const resultsBookmarks = bookmarksByNew.filter((item) => {
        return item.name.toLowerCase().startsWith(keyword.toLowerCase())
      })
      const resultsFolders = foldersByNew.filter((item) => {
        return item.name.toLowerCase().startsWith(keyword.toLowerCase())
      })
      setFoundBookmarks(resultsBookmarks)
      setFoundFolders(resultsFolders)
    } else {
      setFoundBookmarks(currBookmarks.sorted)
      setFoundFolders(currFolders.sorted)
    }

    setTitle(keyword)
  }

  const handleExitSearch = () => {
    setFoundBookmarks(null)
    setFoundFolders(null)
    setTitle('')
  }

  return (
    <>
      <Search
        expand={expand}
        title={title}
        filter={filter}
        foundBookmarks={foundBookmarks}
        handleExitSearch={handleExitSearch}
      />
      <Container expand={expand}>
        <Wrapper>
          <SearchBack expand={expand}>0</SearchBack>
          <SearchBar expand={expand}>
            <SearchInput
              type="text"
              name="search"
              autoCorrect="off"
              placeholder="Search"
              spellcheck="false"
              autocomplete="off"
              value={title}
              onChange={filter}
            />
            {foundBookmarks ? (
              <ExitSearchButton onClick={handleExitSearch}>✖</ExitSearchButton>
            ) : (
              null
            )}
          </SearchBar>

          <FolderTrayContainer expand={expand}>
            <FolderTrayWrapper>
              {foundFolders && foundFolders.length > 0 ? (
                <Folders
                  expand={expand}
                  sort={sortFolders}
                  editMode={editModeF}
                  setBookmarks={setCurrBookmarks}
                  folders={foundFolders}
                  setTitle={setTitle}
                  setFoundBookmarks={setFoundBookmarks}
                  setFoundFolders={setFoundFolders}
                />
              ) : (
                <Folders
                  expand={expand}
                  sort={sortFolders}
                  editMode={editModeF}
                  setBookmarks={setCurrBookmarks}
                  folders={currFolders.sorted.length > 0 ? currFolders.sorted : folders}
                  setTitle={setTitle}
                  setFoundBookmarks={setFoundBookmarks}
                  setFoundFolders={setFoundFolders}
                />
              )}
            </FolderTrayWrapper>
          </FolderTrayContainer>

          <MarksTrayContainer expand={expand}>
            <MarksTrayWrapper>
              {foundBookmarks && foundBookmarks.length > 0 ? (
                <Bookmarks
                  expand={expand}
                  editMode={editModeB}
                  bookmarks={foundBookmarks}
                />
              ) : (
                foundBookmarks && currBookmarks.sorted.length > 0 ? (
                  <Notice>Nothing found</Notice>
                ) :
                  <Bookmarks
                    expand={expand}
                    editMode={editModeB}
                    bookmarks={currBookmarks && currBookmarks?.sorted.length > 0 ? currBookmarks.sorted : bookmarksByNew}
                  />
              )}
            </MarksTrayWrapper>
          </MarksTrayContainer>
        </Wrapper>
      </Container>

      <SideButtons
        expand={expand}
        showForm={showForm}
        setShowForm={setShowForm}
        editModeB={editModeB}
        setEditModeB={setEditModeB}
        editModeF={editModeF}
        setEditModeF={setEditModeF}
        setSettingsMode={setSettingsMode}
        folderForm={folderForm}
        setFolderForm={setFolderForm}
        setSort={setSort}
        sort={sort}
        handleSort={handleSort}
        handleSortFolders={handleSortFolders}
        sortFolders={sortFolders}
        setSortFolders={setSortFolders}
      />
    </>
  )
}

export default Tray

