import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../globalStyles'
import { Form, FormContainer, FormWrapper, Input, PageName } from './LoginForm.elements'

const LoginForm = ({ login }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = (event) => {
    event.preventDefault()
    try {
      login(username, password)
      setUsername('')
      setPassword('')
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <FormContainer>
      <FormWrapper>
        <PageName>Log in</PageName>
        <Form onSubmit={handleLogin}>
          <div className="inputs">
            Username:
            <br />
            <Input
              id="username"
              type="text"
              value={username}
              title="Username"
              onChange={({ target }) => setUsername(target.value)}
            />
          </div>
          <div className="inputs">
            Password:
            <br />
            <Input
              id="password"
              type="password"
              value={password}
              title="Password"
              onChange={({ target }) => setPassword(target.value)}
            />
          </div>
          <Link to='/signup' style={{ color: '#9999dd' }}>New? Sign up</Link>
          <Button right variant={'#15ac61'} id="login-button" type="submit">
            Login
          </Button>
        </Form>
      </FormWrapper>
    </FormContainer>
  )
}

export default LoginForm
