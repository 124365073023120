import styled from 'styled-components'

export const FormContainer = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: #1e1e1eee;
    position: absolute;
    z-index: 999;
`

export const FormWrapper = styled.div`
    width: 500px;
    top: 25vh;
    background-color: #332828;
    background-color: #141415;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid #2d2d31; 
    margin: auto;
    position: relative;
`

export const PageName = styled.div`
    font-size: 18px;
    width: 100%;
    line-height: 24px;
    padding: 20px;
    color: #9a9a9a;
    border-bottom: 1px solid #2d2d31;
    background-color: #111111dd;
    pointer-events: none;
`

export const Form = styled.form`
    padding: 20px 25px;
    margin-bottom: 7px;
    color: #9a9a9a;
`

export const Input = styled.input`
    width: 100%;
    background-color: #333333;
    border: none;
    border-radius: 4px;
    padding: 12px;
    margin: 12px 0;
    color: #9a9a9a;

    &:focus {
        outline: 1px solid #666666;
    }
`

export const Select = styled.select`
    width: 100%;
    background-color: #333333;
    border: none;
    border-radius: 4px;
    padding: 12px;
    margin: 12px 0;
    color: #9a9a9a;
    outline: 2px solid #4e4e4e;
    outline-offset: -2px;

    &:focus {
        outline: 1px solid #666666;
    }
`

export const Option = styled.option`
    padding: 12px;
    line-height: 30px;
`