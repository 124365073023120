import bookmarkService from '../services/bookmarks'

export const initializeBookmarks = (userId) => {
  return async (dispatch) => {
    const folders = await bookmarkService.getAll(userId)

    let allBookmarks = folders.map((props) => (props.bookmarks))
    let bookmarks = allBookmarks.flat()

    dispatch({
      type: 'INIT_BOOKMARKS',
      data: bookmarks,
    })
  }
}

export const addNewBookmark = (id, content) => {
  return async (dispatch) => {
    const bookmarkId = id
    let newBookmark = await bookmarkService.create(bookmarkId, content)
    newBookmark = { ...newBookmark, bookmarkId }
    dispatch({
      type: 'NEW_BOOKMARK',
      data: newBookmark,
    })
  }
}

export const removeBookmark = (id) => {
  return async (dispatch) => {
    await bookmarkService.remove(id)
    dispatch({
      type: 'DELETE_BOOKMARK',
      data: { id },
    })
  }
}

const reducer = (state = [], action) => {
  const { type, data } = action

  switch (type) {
  case 'INIT_BOOKMARKS':
    return data

  case 'NEW_BOOKMARK':
    return [...state, data]

  case 'DELETE_BOOKMARK': {
    const { id } = action.data
    return state.filter((bookmark) => bookmark.id !== id)
  }

  default:
    return state
  }
}

export default reducer