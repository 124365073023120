import { useSelector } from 'react-redux'
import {
  Form,
  FormContainer,
  FormWrapper,
  PageName
} from './Settings.elements'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, ExitButton } from '../../globalStyles'
import { VscClose } from 'react-icons/vsc'
import { IconContext } from 'react-icons/lib'
import { logout } from '../../reducers/loginReducer'

const BookmarkForm = ({ setSettingsMode }) => {
  const user = useSelector((state) => state.login)
  console.log(user)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    window.localStorage.removeItem('loggedUser')
    dispatch(logout())
    navigate('/login')
    window.location.reload()
  }

  return (
    <>
      <IconContext.Provider value={{ size: 26, color: '#65656d' }}>
        <FormContainer>
          <FormWrapper>
            <PageName>
              Settings
              <ExitButton right onClick={() => setSettingsMode(false)}>
                <VscClose />
              </ExitButton>
            </PageName>
            <Form>
              <p>
                Currently logged in as <strong><i>{user.username}</i></strong>
              </p>
              <Button variant={'#252525'} onClick={handleLogout}>Log out</Button>
            </Form>
            <hr/>
          </FormWrapper>
        </FormContainer>
      </IconContext.Provider>
    </>
  )
}

export default BookmarkForm