import loginService from '../services/login'

const loggedUserJSON = JSON.parse(
  window.localStorage.getItem('loggedAnaquelUser'),
)

const initialState = loggedUserJSON ? loggedUserJSON : null

export const login = (username, password) => {
  return async (dispatch) => {
    const user = await loginService.login({ username, password })
    dispatch({
      type: 'LOGIN',
      data: user,
    })
  }
}

export const signup = (username, password, token) => {
  return async (dispatch) => {
    const user = await loginService.signup({ username, password, token })
    dispatch({
      type: 'SIGNUP',
      data: user,
    })
  }
}

export const logout = () => {
  return {
    type: 'LOGOUT',
  }
}

const loginReducer = (state = initialState, action) => {
  switch (action.type) {

  case 'LOGIN':
    return action.data

  case 'SIGNUP':
    return null

  case 'LOGOUT':
    return null

  default:
    return state
  }
}

export default loginReducer