import React from 'react'
import {
  SearchContainer,
  TextContainer,
  SearchBar,
  SearchInput,
  IconWrap
} from './Search.elements'

const Dashboard = ({ title, filter, foundBookmarks, handleExitSearch }) => {

  return (
    <>
      <SearchContainer>
        <TextContainer>
          <SearchBar autocomplete="off">
            {foundBookmarks ? (
              <IconWrap onClick={handleExitSearch}>✖</IconWrap>
            ) : (
              null
            )}

            <SearchInput
              name="search"
              type="text"
              spellcheck="false"
              autocomplete="off"
              placeholder="Search"
              value={title}
              onChange={filter}
            />
          </SearchBar>
        </TextContainer>
      </SearchContainer>
    </>
  )
}

export default Dashboard

