import styled from 'styled-components'

export const SideBarContainer = styled.div`
    width: 100%;
    height: 100%;
    padding-right: 2px;
    padding-left: 4px;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.2s;
`

export const SideBarWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    user-select: none;
    overflow-y: overlay;
    padding: 1vw;
`

export const NameContainer = styled.div`
    height: 30px;
    width: 100%;
    overflow: hidden;
`

export const NameWrapper = styled.div`
    height: 30px;
    width: 100vw;
`

export const Name = styled.h5`
    color: #888;
    font-size: 14px;
    line-height: 26px;
    margin-top: 0px;
    cursor: default;
    font-weight: 300;
    display: inline-block;
    vertical-align: top;
    user-select: text;
`

export const FolderContainer = styled.div`
    width: 6.8vw;
    margin: 1vw;
    position: relative;
    display: inline-block;
    vertical-align: top;

    @media screen and (max-width: 991px) {
        width: 20vw;
        margin: 1vw;
    }
`

export const FolderWrapper = styled.div`
    height: 6.8vw;
    width: 6.8vw;
    border-radius: 20px;
    background-color: #111;
    outline-offset: -0px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    padding: 0.46vw;

    &:hover {
        outline: 2px solid #888;
    }

    @media screen and (max-width: 991px) {
        width: 20vw;
        height: 20vw;
        padding: 2vw;
    }
`

export const Favicon = styled.img`
    width: 100%;
    height: 100%;
    vertical-align: top;
    object-fit: cover;
    object-position: left;
    border-radius: 12px;
    opacity: 0.25;
`

export const FolderIconContainer = styled.div`
    width: 2.5vw;
    height: 2.5vw;
    margin: 0.22vw;
    object-fit: cover;
    object-position: left;
    background-color: ${({ folderColor }) => (folderColor)};
    border-radius: 12px;
    opacity: 1;
    display: inline-block;

    @media screen and (max-width: 991px) {
        width: 7vw;
        height: 7vw;
        margin: 0.5vw;
    }
`

export const FolderFaviconContainer = styled(FolderIconContainer)`
    background-color: ${({ folderColor }) => (folderColor)}44;
    
    ${FolderWrapper}:hover & {
        outline: 6px solid #00000033;
        outline-offset: -6px;
    }
`

export const FolderIconWrapper = styled.div`
    margin: auto;
    width: 2.5vw;
    height: 2.5vw;
    cursor: pointer;
    text-decoration: none;
    display: block;
    text-align: center;
`

export const LetterIcon = styled.div`
    margin: auto;
    font-size: 1.5vw;
    line-height: 2.3vw;
    font-weight: 800;
    display: inline-block;
    vertical-align: top;
    color: ${({ letterColor }) => (letterColor)};

    @media screen and (max-width: 991px) {
        font-size: 4.5vw;
        line-height: 6.5vw;
        margin-left: -0.4vw;
    }
`

export const SmallFolderIconContainer = styled.div`
    width: 1.52vw;
    height: 1.52vw;
    margin: 0.22vw;
    object-fit: cover;
    object-position: left;
    background-color: #45454577;
    background-color: ${({ fColor }) => (fColor)}dd;
    border-radius: 9px;
    opacity: 1;
    display: inline-block;

    @media screen and (max-width: 991px) {
        width: 3.48vw;
        height: 3.48vw;
        margin: 0.22vw;
    }
`

export const SmallFolderIconWrapper = styled.div`
    margin: auto;
    width: 1.52vw;
    height: 1.52vw;
    cursor: pointer;
    text-decoration: none;
    display: block;
    text-align: center;
`

export const SmallLetterIcon = styled.div`
    margin: auto;
    color: #0004;
    font-size: 0.95vw;
    line-height: 1.4vw;
    font-weight: 800;
    display: inline-block;
    vertical-align: top;
    text-align: center;

    @media screen and (max-width: 991px) {
        font-size: 2.25vw;
        line-height: 3.25vw;
    }
`

export const SideSpacer = styled.div`
    width: 50%;
    height: 100px;
    object-fit: cover;
    object-position: left; 
    opacity: 0.75;
    display: inline-block;
`

export const FolderImage = styled.img`
    width: 16.66%;
    height: 100px;
    object-fit: cover;
    object-position: left;
    outline: 1px solid #111;  
    opacity: 0.75;
    
    ${FolderWrapper}:hover & {
        height: 110px;
        opacity: 1;
    }
    
    @media screen and (max-width: 1500px) { 
        height: 80px;
    } 
`

//Search
export const SearchBar = styled.form`
    bottom: 0;
    width: 100%;
    height: 62px;
    position: absolute;
    border-top: 1px solid #555;
    overflow: hidden;
    
    @media screen and (max-width: 1500px) { 
        height: 54px;
    }
`

export const SearchInput = styled.input`
    padding: 11px 0px 11px 10px;
    margin-top: 10px;
    font-size: 18px;
    outline: none;
    border: none;
    width: 80%;
    display: inline-block;
    background: #222;
    color: #000;
    position: absolute;

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
    }

    &::placeholder {
        color: #666;
    }
    
    @media screen and (max-width: 1500px) { 
        margin-top: 5px;
    }

    @media screen and (max-width: 991px) {
        background-color: #11111100;
        border-bottom: 1px solid #2d2d3100;
        padding: 0.65em 1.8em 0 0;
        text-align: center;
    }
`

export const SearchButton = styled.div`
    height: 42px;
    width: 36px;
    padding: 18px 0px 8px 12px;
    background: rgba(255,255,255,0.05);
    color: rgba(255,255,255,0.75);
    font-size: 1.2rem;
    outline: none;
    border: none;
    display: inline-block;
    border-right: 1px solid #555;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }

    @media screen and (max-width: 1500px) { 
        padding: 14px 0px 8px 12px;
    }
`

export const SearchBack = styled.div`
    font-size: 18px;
    width: 20.4vw;
    line-height: 24px;
    border-radius: 1.25rem 0 0 0;
    padding: 0.9em 1.5em 1.25vw 1.5em;
    color: #9a9a9a00;
    border-bottom: 1px solid #29292cdd;
    background-color: #29292ccc;
    position: fixed;
    z-index: 2;
`

export const SpacerSmall = styled.div`
    display: block;
    bottom: 0;
    height: 3rem;
    width: 100%;
`

export const SpacerMedium = styled.div`
    display: block;
    bottom: 0;
    height: 120px;
    width: 100%;
`

export const PageEncase = styled.img`
    width: 6.8vw;
    padding: 0vw;
    margin: -0.45vw;
    position: absolute;
    opacity: 0.6;
`

export const Breadcrumb = styled.div`
    font-size: 18px;
    width: 47.6vw;
    margin-left: 20.4vw;
    line-height: 24px;
    padding: 1.1em 1.5em 1.15vw 1.5em;
    border-radius: 0 1.25rem 0 0;
    color: #9a9a9a;
    border-bottom: 1px solid #2d2d31;
    background-color: #111111dd;
    position: absolute;
    z-index: 3;
    pointer-events: none;
    transition: 0.2s;
    margin-top: ${({ expand }) => (expand ? '0px' : '-100px')};
    opacity: ${({ expand }) => (expand ? '1' : '0')};

    @media screen and (max-width: 991px) {
        padding: 0.9em;
        text-align: center;
        margin-left: 0vw;
        width: 100%;
        margin-top: ${({ expand }) => (expand ? '0px' : '0px')};
        opacity: ${({ expand }) => (expand ? '1' : '1')};
        margin-left: 100%;
    }
`

export const DeleteButton = styled.span`
    font-size: 10px;
    line-height: 12px;
    height: 19px;
    width: 19px;
    padding: 3px 5px;
    border-radius: 12px;
    position: absolute;
    top: 0;
    display: inline-block;
    vertical-align: top;
    background-color: #656565;    
    outline: 0px solid #656565;
    color: #f0f0f0;
    z-index: 999999999;
    margin: -3px;
    transition: 0.1s;
    scale: 0;
    scale: ${({ editMode }) => (editMode ? '1' : '0')};
    cursor: pointer;
    z-index: 10;

    &:hover {
        background-color: #a40004;
        outline: 2px solid #a40004;
        outline-offset: -1px;
    }
`