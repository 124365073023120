import React from 'react'
import {
  ExpandContainer,
  TextContainer,
  TextWrapper
} from './Expand.elements'

const Dashboard = ({ expand, setExpand, setEditModeF, setEditModeB }) => {
  const handleExpand = () => {
    setExpand(!expand)
    setEditModeF(false)
    setEditModeB(false)
  }
  return (
    <>
      <ExpandContainer expand={expand}>
        <TextContainer onClick={handleExpand}>
          <TextWrapper>{expand ? 'Collapse' : 'Expand'}</TextWrapper>
        </TextContainer>
      </ExpandContainer>
    </>
  )
}

export default Dashboard

