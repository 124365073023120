import React, { useEffect } from 'react'
import GlobalStyle from './globalStyles'
import { useDispatch, useSelector } from 'react-redux'
import WebFont from 'webfontloader'
import { Route, Routes } from 'react-router-dom'
import { initializeFolders } from './reducers/folderReducer'
import { initializeBookmarks } from './reducers/bookmarkReducer'
import bookmarkService from './services/bookmarks'
import Home from './pages/Home'
import Login from './pages/Login'
import Signup from './pages/Signup'

const App = () => {
  const user = useSelector((state) => state.login)
  const dispatch = useDispatch()

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Shippori Antique']
      }
    })
  }, [])

  useEffect(() => {
    dispatch(initializeFolders(user?.id))
    dispatch(initializeBookmarks(user?.id))
  }, [])

  useEffect(() => {
    const loggedInUserJSON = JSON.parse(
      window.localStorage.getItem('loggedAnaquelUser'),
    )
    if (loggedInUserJSON) {
      const user = loggedInUserJSON
      bookmarkService.setToken(user?.token)
    }
  }, [])

  useEffect(() => {
    window.localStorage.setItem('loggedAnaquelUser', JSON.stringify(user))
    bookmarkService.setToken(user?.token)
  }, [user])

  return (
    <>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
      </Routes>
    </>
  )
}

export default App