/* eslint-disable no-unused-vars */
import React from 'react'
import { useDispatch } from 'react-redux'
import { removeBookmark } from '../../reducers/bookmarkReducer'
import * as s from './Bookmarks.elements'
import tempImage from '../../assets/anaquel_logo_black.svg'
import { TempImage } from '../../globalStyles'

const WebsiteIcon = ({ editMode, ...props }) => {
  const handleIcon = () => {
    let short = props?.url.split('/')
    return short[2]
  }

  const dispatch = useDispatch()

  const deleteBookmark = async () => {
    try {
      if (window.confirm(`Delete "${props?.name}"?`)) {
        dispatch(removeBookmark(props.id))
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <s.WebsiteCard>
        {props.isApp ?
          <>
            <s.WebsiteApp>
              <s.WebsiteContainer>
                <s.WebsiteWrapper href={props.url} target="_blank">
                  <s.WebsiteImage src={`https://icon.horse/icon/${handleIcon()}`} draggable="false" alt='bookmark icon' />
                </s.WebsiteWrapper>
              </s.WebsiteContainer>
            </s.WebsiteApp>
          </>
          :
          <s.WebsiteContainer>
            <s.DeleteButton onClick={deleteBookmark} editMode={editMode}>✖</s.DeleteButton>
            <s.WebsiteWrapper href={props.url} target="_blank">
              <s.WebsiteImage src={`https://icon.horse/icon/${handleIcon()}`} draggable="false" alt='bookmark icon' />
            </s.WebsiteWrapper>
          </s.WebsiteContainer>
        }
        <s.NameContainer>
          <s.NameWrapper>
            <s.Name href={props.url} target="_blank">{props.name}</s.Name>
          </s.NameWrapper>
        </s.NameContainer>
      </s.WebsiteCard>
    </>
  )
}

const AllBookmarks = ({ expand, editMode, bookmarks }) => {
  return (
    <>
      <s.SpacerXSmall expand={expand} />
      <s.BookmarksContainer expand={expand}>
        {bookmarks?.length === 0 ?
          'This folder is empty'
          :
          bookmarks?.map((props, id) => (
            <WebsiteIcon key={id} editMode={editMode} {...props} />
          ))
        }
      </s.BookmarksContainer>
    </>
  )
}

export default AllBookmarks
