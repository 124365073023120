import React from 'react'
import {
  FooterContainer,
  FooterWrapper,
  TextContainer,
  TextContainerRight,
  TextWrapper
} from './Footer.elements'

const Dashboard = () => {
  return (
    <>
      <FooterContainer>
        <FooterWrapper>

          <TextContainer>
            <TextWrapper to="/">Github</TextWrapper>
          </TextContainer>

          <TextContainerRight>
            <TextWrapper to="/">Anaquel (Beta v0.0.0)</TextWrapper>
          </TextContainerRight>

        </FooterWrapper>
      </FooterContainer>
    </>
  )
}

export default Dashboard
