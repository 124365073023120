import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addNewBookmark } from '../reducers/bookmarkReducer'
import { addNewFolder } from '../reducers/folderReducer'
import BookmarkForm from '../components/BookmarkForm/BookmarkForm'
import FolderForm from '../components/FolderForm/FolderForm'
import Settings from '../components/Settings/Settings'

import Tray from '../components/Tray/Tray'
import Expand from '../components/Expand/Expand'
import Footer from '../components/Footer/Footer'
import Login from './Login'

const Home = () => {
  const [expand, setExpand] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [folderForm, setFolderForm] = useState(false)

  const [editModeF, setEditModeF] = useState(false)
  const [editModeB, setEditModeB] = useState(false)
  const [settingsMode, setSettingsMode] = useState(false)

  const user = useSelector((state) => state.login)
  const folders = useSelector((state) => state.folders)
  const bookmarks = useSelector((state) => state.bookmarks)
  const dispatch = useDispatch()

  const addBookmark = (id, { name, folder, url, date, favorite }) => {
    dispatch(addNewBookmark(id, { name, folder, url, date, favorite }))
  }

  const addFolder = (content) => {
    dispatch(addNewFolder(content))
  }

  return (
    <>

      {showForm && (
        <BookmarkForm addNewBookmark={addBookmark} setShowForm={setShowForm} />
      )}
      {folderForm && (
        <FolderForm addNewFolder={addFolder} setFolderForm={setFolderForm} />
      )}
      {settingsMode && (
        <Settings setSettingsMode={setSettingsMode} />
      )}
      {user === null ? <Login />
        : (
          <>
            <Tray
              expand={expand}
              folders={folders}
              bookmarks={bookmarks}
              showForm={showForm}
              setShowForm={setShowForm}
              editModeF={editModeF}
              setEditModeF={setEditModeF}
              editModeB={editModeB}
              setEditModeB={setEditModeB}
              settingsMode={settingsMode}
              setSettingsMode={setSettingsMode}
              folderForm={folderForm}
              setFolderForm={setFolderForm}
            />
            <Expand
              expand={expand}
              setExpand={setExpand}
              setEditModeF={setEditModeF}
              setEditModeB={setEditModeB}
            />
            <Footer />
          </>
        )}
    </>
  )
}

export default Home