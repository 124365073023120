/* eslint-disable no-unused-vars */
import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  ColorInput,
  Form,
  FormContainer,
  FormWrapper,
  Input,
  PageName
} from './FolderForm.elements'
import { Button, ExitButton } from '../../globalStyles'
import { VscClose } from 'react-icons/vsc'
import { IconContext } from 'react-icons/lib'
import { useSelector } from 'react-redux'

const FolderForm = ({ addNewFolder, setFolderForm }) => {
  const [inputValues, setInputValues] = useState({ name: '', color: '#9486FE' })
  const [borderAlert, setBorderAlert] = useState('#33333300')
  const folders = useSelector((state) => state.folders)

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    setBorderAlert('#33333300')
    setInputValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      }
    })
  }

  const handleAddFolder = (event) => {
    event.preventDefault()
    const name = inputValues?.name
    const color = inputValues?.color

    const allNames = folders.map((folder) => folder.name)

    if ([name].some((item) => allNames.includes(item)) === false) {
      const folder = {
        name,
        color
      }

      addNewFolder(folder)
      setInputValues({ name: '', color: '#9486FE' })
      setBorderAlert('#33333300')
    } else {
      setBorderAlert('#ff0000')
      console.log('A folder with this name already exists')
    }
  }

  const handleKey = (e) => {
    if (e.which === 13) {
      e.preventDefault()
    }
  }

  return (

    <IconContext.Provider value={{ size: 26, color: '#65656d' }}>
      <FormContainer>
        <FormWrapper>
          <PageName>
            Add a new bookmark
            <ExitButton right onClick={() => setFolderForm(false)}>
              <VscClose />
            </ExitButton>
          </PageName>
          <Form onSubmit={handleAddFolder}>
            <div>
              Folder
              <br />
              <Input
                required
                name="name"
                onKeyDown={handleKey}
                value={inputValues?.name || ''}
                onChange={handleInputChange}
                borderAlert={borderAlert}
              />
              Color
              <br />
              <ColorInput
                type="color"
                id="color"
                name="color"
                value={inputValues?.color || ''}
                onChange={handleInputChange}
              />
            </div>
            <Button variant={'#15ac61'} right type="submit">Save</Button>
          </Form>
        </FormWrapper>
      </FormContainer>
    </IconContext.Provider>
  )
}

export default FolderForm

FolderForm.propTypes = {
  addNewFolder: PropTypes.func.isRequired,
}

