import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SideBarContainer,
  SideBarWrapper,
  FolderContainer,
  FolderWrapper,
  SmallFolderIconContainer,
  SmallFolderIconWrapper,
  SmallLetterIcon,
  NameContainer,
  NameWrapper,
  Name,
  FolderFaviconContainer,
  FolderIconContainer,
  FolderIconWrapper,
  LetterIcon,
  Favicon,
  Breadcrumb,
  DeleteButton
} from './Folders.elements'
import { IconContext } from 'react-icons/lib'
import { SpacerSmall } from '../Tray/Tray.elements'
import { removeFolder } from '../../reducers/folderReducer'

const SmallIcon = ({ name }) => {
  return (
    <SmallFolderIconContainer folderColor={'#222222'}>
      <SmallFolderIconWrapper>
        <SmallLetterIcon>{name.slice(0, 1)}</SmallLetterIcon>
      </SmallFolderIconWrapper>
    </SmallFolderIconContainer>
  )
}

const MediumIcon = ({ url }) => {
  const handleIcon = (url) => {
    let short = url?.split('/')
    return short[2]
  }
  return (
    <FolderIconContainer folderColor={'#191919'}>
      <FolderIconWrapper>
        <Favicon src={`https://icon.horse/icon/${handleIcon(url)}`} letterColor={'#454545'} />
      </FolderIconWrapper>
    </FolderIconContainer>
  )
}

const FolderIcon = ({ name, fColor }) => {
  return (
    <FolderFaviconContainer folderColor={fColor}>
      <FolderIconWrapper>
        <LetterIcon letterColor={fColor}>{name}</LetterIcon>
      </FolderIconWrapper>
    </FolderFaviconContainer>
  )
}

const Folder = ({ isAll, fColor, editMode, setBookmarks, setCrumb, sort, setTitle, setFoundBookmarks, setFoundFolders, ...props }) => {
  const handleCurrBookmarks = () => {
    setTitle('')
    setFoundBookmarks(null)
    setFoundFolders(null)
    const sortAz = props.bookmarks.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    if (sort === 'AZ') {
      setBookmarks({ sorted: sortAz, unsorted: props.bookmarks })
    } else if (sort === 'ZA') {
      setBookmarks({ sorted: sortAz.reverse(), unsorted: props.bookmarks })
    } else if (sort === 'OLD') {
      setBookmarks({ sorted: props.bookmarks, unsorted: props.bookmarks })
    } else {
      const bookmarksByNew = [...props.bookmarks].reverse()
      setBookmarks({ sorted: bookmarksByNew, unsorted: props.bookmarks })
    }
    setCrumb('/ ' + props.name)
  }

  const dispatch = useDispatch()

  const deleteFolder = async () => {
    try {
      if (window.confirm(`Delete "${props?.name}"?`)) {
        dispatch(removeFolder(props.id))
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <FolderContainer onClick={handleCurrBookmarks}>
      <DeleteButton onClick={deleteFolder} editMode={editMode}>✖</DeleteButton>
      <FolderWrapper>
        {isAll ?
          <>
            {props.bookmarks?.slice(0, 9).map((props, id) => (
              <SmallIcon key={id} name={props.name} />
            ))}
          </>
          :
          <>
            <FolderIcon name={props.name?.slice(0, 1)} fColor={fColor} />
            {props.bookmarks?.slice(0, 3).map((props, id) => (
              <MediumIcon key={id} url={props.url} />
            ))}
          </>
        }
      </FolderWrapper>
      <NameContainer>
        <NameWrapper>
          <Name>{props.name}</Name>
        </NameWrapper>
      </NameContainer>
    </FolderContainer>
  )
}

const Folders = ({ expand, folders, setBookmarks, sort, setTitle, setFoundBookmarks, setFoundFolders, editMode }) => {
  const [crumb, setCrumb] = useState('')
  const bookmarks = useSelector((state) => state.bookmarks)
  return (
    <>
      <IconContext.Provider value={{ size: 40, color: '#999' }}>
        <SideBarContainer expand={expand}>
          <SideBarWrapper>
            <SpacerSmall />

            {/* <Folder isAll name={'All Apps'} bookmarks={bookmarks} /> */}
            <Folder
              isAll
              name={'All Bookmarks'}
              setBookmarks={setBookmarks}
              sort={sort}
              setCrumb={() => setCrumb('')}
              setTitle={setTitle}
              bookmarks={bookmarks}
              setFoundBookmarks={setFoundBookmarks}
              setFoundFolders={setFoundFolders}
            />

            {folders?.map((props, id) => (
              <Folder
                key={id}
                fColor={props?.color}
                setBookmarks={setBookmarks}
                sort={sort}
                editMode={editMode}
                setCrumb={setCrumb}
                setTitle={setTitle}
                setFoundBookmarks={setFoundBookmarks}
                setFoundFolders={setFoundFolders}
                {...props}
              />
            ))}

          </SideBarWrapper>
        </SideBarContainer>

        <Breadcrumb expand={expand}>
          All Bookmarks {crumb}
        </Breadcrumb>
      </IconContext.Provider>
    </>
  )
}

export default Folders
