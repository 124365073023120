import bookmarkService from '../services/bookmarks'

export const initializeFolders = (userId) => {
  return async (dispatch) => {
    const folders = await bookmarkService.getAllFolders(userId)
    dispatch({
      type: 'INIT_FOLDERS',
      data: folders,
    })
  }
}

export const addNewFolder = (content) => {
  return async (dispatch) => {
    const folder = await bookmarkService.createFolder(content)
    dispatch({
      type: 'NEW_FOLDER',
      data: folder,
    })
  }
}

export const removeFolder = (id) => {
  return async (dispatch) => {
    await bookmarkService.removeFolder(id)
    dispatch({
      type: 'DELETE_FOLDER',
      data: { id },
    })
  }
}

const reducer = (state = [], action) => {
  const { type, data } = action

  switch (type) {
  case 'INIT_FOLDERS':
    return data

  case 'NEW_FOLDER':
    return [...state, data]

  case 'DELETE_FOLDER': {
    const { id } = action.data
    return state.filter((folder) => folder.id !== id)
  }

  default:
    return state
  }
}

export default reducer